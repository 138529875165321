import React from "react";
import classes from "./InputAutocomplite.module.scss";
import Paper from "../paper/Paper";
import CloseRed from "../icons/CloseRed";
import PlusRound from "../icons/PlusRound";

interface ISelectedType<T> {
  label: string;
  data?: T;
  value: string;
}

export default function InputAuto<T>({
  label,
  placeholder,
  onCreate,
  onSelected,
  onChange,
  selected,
  suggestions,
  search = "",
  initialValue,
  autocomplite = true,
  disabled,
}: {
  autocomplite?: boolean;
  initialValue?: string;
  label?: string;
  placeholder?: string;
  onCreate?: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: ISelectedType<T>[];
  suggestions: ISelectedType<T>[];
  search?: string;
  onSelected: (
    type: "add" | "sub" | "clear",
    value?: ISelectedType<T> | null,
  ) => void;
  disabled?: boolean;
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const hideSuggs = (value: ISelectedType<T>, isActive: boolean) => {
    onSelected(isActive ? "sub" : "add", value);
  };

  return (
    <div className={classes.searchWrap}>
      <div className={classes.formControlAuto}>
        <label className={classes.label} htmlFor="tag-input">
          {label}
        </label>
        <input
          placeholder={placeholder}
          value={search}
          onChange={handleChange}
          defaultValue={initialValue}
          className={classes.search}
          disabled={disabled}
        />
        {onCreate && (
          <button
            className={classes.endAdornment}
            type="button"
            onClick={() => onCreate(search)}
          >
            {search.length > 1 && <PlusRound />}
          </button>
        )}
      </div>
      {suggestions &&
        suggestions.length > 0 &&
        autocomplite &&
        search.length > 0 && (
        <Paper
          cls={{
            root: classes.autocomplite + " ",
            paddingRoot: classes.autocomplitePadding,
          }}
        >
          <ul className={`${classes.autocompliteList}`} data-element="list">
            {suggestions.map((item, index) => {
              const isActive = !!selected.find(
                (select) => select.value === item.value,
              );
              return (
                <li
                  className={`${classes.autocompliteListItem} ${
                    isActive ? classes.active : ""
                  }`}
                  key={index}
                  data-element="list-item"
                  onClick={() => hideSuggs(item, isActive)}
                >
                  <span className={classes.autocompliteListItemName}>
                    {item.label}
                  </span>
                  {isActive ? <CloseRed /> : <PlusRound />}
                </li>
              );
            })}
          </ul>
        </Paper>
      )}
    </div>
  );
}
